import gql from 'graphql-tag';

export const GET_FEAUTURED_PRODUCTS_REQUEST = gql`
  query($id: Float!) {
    SuggestedProducts(id: $id) {
      id
      slug
      description {
        en
        ru
      }
      name {
        en
        ru
      }
      isActive
      isHidden
      isDeleted
      photos {
        createdAt
        path
        type
      }
      prices {
        endAt
        startAt
        isBase
        list {
          amount
          currency
        }
      }
      inDetail {
        type
        material {
          options {
            id
            name {
              en
              ru
            }
          }
          colorOptions {
            id
            hexCode
          }
          measurement {
            height
            width
            weight
            depth
            unit
          }
        }
        projects {
          id
          endAt
          startAt
          saleStartAt
          # saleEndAt
          name {
            en
            ru
          }
        }
      }
      prices {
        list {
          id
          amount
          currency
        }
        isBase
      }
    }
  }
`;
