import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';
import { Row, Col, Tabs, Descriptions, Spin, Divider } from 'antd';
import parse from 'html-react-parser';
import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';
import Slider from 'react-slick';
import { rgba } from 'polished';
import classnames from 'classnames';
import ReactPlayer from 'react-player';
import { useSelector } from 'react-redux';
import { Link } from 'gatsby';
import { Page, Container, IndexLayout, LayoutBG, AdaptiveLayout } from '../components/Layouts/Layouts';
import { Gallery, CardItem, Button } from '../components/components';
import { SmallItemsList, SliderBlock, ProjectsModules } from '../modules/modules';
import { ProductItem } from '../modules/SharedModules/Products/ProductItem/ProductItem';
import { PayformProductResponseDto, LanguageType, PayformOptionCategoryResponseDto, StrapiProducts } from '../graphql-types';
import css from './product_page.module.scss';
import { CardItemType, ProductCartType, SmallItemType, SliderBlocksType, FontWeight } from '../lib/constants/enums';
import { GET_FEAUTURED_PRODUCTS_REQUEST } from '../api/api-apollo/query/feautured';
import { OPTIONS_CATEGORY_REQUEST } from '../api/api-apollo/query/optionCategory';
import { StrapiBlocksName, ProjectCategories } from '../lib/constants/strapi';
import { pathTo, replaceStrapiComponentName } from '../lib/utils';
import { colors, breakpoints } from '../styles/variables';
import ColorService from '../lib/services/colorService';
import { strapiSelector } from '../redux/strapi/strapi.selectors';
import remarkGfm from 'remark-gfm';

const productImageStub = require('../assets/icons/product-image-stub.svg');

interface PageContext {
  product: PayformProductResponseDto;
  productStrapi: StrapiProducts;
  slug: string;
  lang: string;
  id: string;
}

interface PageTemplateProps {
  pageContext: PageContext;
}

interface GCategoryItems {
  OptionsCategory: PayformOptionCategoryResponseDto[];
}

interface SelelectItemProps {
  label?: string | undefined | null;
  head?: string | undefined | null;
  mainContent?: string | undefined | null;
  footer?: string | undefined | null;
  additionalTitle?: string | undefined | null;
  color?: string | undefined | null;
  arrows: {
    bulletArrow: string | undefined | null;
    additionalBulletArrow?: string | undefined | null;
    labelArrow: string | undefined | null;
  };
}

const NavStyled = styled.div`
  ul li {
    position: relative;
    list-style: ${props => (props.bulletIcon ? 'none' : 'disc')};
    &::before {
      content: '';
      position: absolute;
      top: 7px;
      left: -30px;
      background: ${props =>
        props.bulletIcon ? `url(${process.env.STRAPI_API_URL}${props.bulletIcon}) center center / cover no-repeat` : 'none'};
      width: 19px;
      height: 15px;
      @media (max-width: ${`${breakpoints.md}px`}) {
        top: 3px;
      }
    }
  }
`;

const StyledTitle = styled.div`
  & strong {
    color: ${props => (props.color ? props.color : colors.black)};
  }
`;

const SelelectItem: React.FC<SelelectItemProps> = ({ label, head, mainContent, footer, additionalTitle, color, arrows }) => {
  const [isView, setView] = useState<boolean>(false);
  return (
    <div style={{ border: color ? `1px solid ${color}` : 'none' }} className={css.selectItem}>
      <div className={css.label} onClick={() => setView(prev => !prev)}>
        <div className={css.labelTitle}>{label}</div>
        {arrows.labelArrow && (
          <div
            style={{ background: `url(${process.env.STRAPI_API_URL}${arrows.labelArrow})center center / contain no-repeat` }}
            className={classnames(css.selectArrow, { [css.selectArrowActive]: isView })}
          />
        )}
      </div>
      {isView && (
        <div className={css.selectContent}>
          {head && (
            <div className={css.selectHead}>
              <ReactMarkdown transformImageUri={img => `${process.env.STRAPI_API_URL}${img}`} children={head} remarkPlugins={[remarkGfm]}/>
            </div>
          )}
          {mainContent && (
            <NavStyled bulletIcon={arrows.bulletArrow} className={css.selectmainContent}>
              <ReactMarkdown transformImageUri={img => `${process.env.STRAPI_API_URL}${img}`} children={mainContent} remarkPlugins={[remarkGfm]} />
            </NavStyled>
          )}
          {additionalTitle && (
            <div className={css.selectAdditionalTitle}>
              <ReactMarkdown transformImageUri={img => `${process.env.STRAPI_API_URL}${img}`} children={additionalTitle} remarkPlugins={[remarkGfm]} />
            </div>
          )}
          {footer && (
            <NavStyled bulletIcon={arrows.bulletArrow} className={css.selectFooter}>
              <StyledTitle color={color}>
                <ReactMarkdown transformImageUri={img => `${process.env.STRAPI_API_URL}${img}`} children={footer} remarkPlugins={[remarkGfm]} />
              </StyledTitle>
            </NavStyled>
          )}
        </div>
      )}
    </div>
  );
};

const PageTemplate: React.FC<PageTemplateProps> = ({ pageContext }) => {
  const {
    strapi: { projects },
  } = useSelector(strapiSelector);
  const { product, id } = pageContext;
  const { TabPane } = Tabs;
  const { t, i18n } = useTranslation();
  const isXS = useMediaQuery({ maxWidth: 480 });
  const isMD = useMediaQuery({ minWidth: 480, maxWidth: 768 });
  const { data: categories } = useQuery<GCategoryItems>(OPTIONS_CATEGORY_REQUEST);
  const [currencyRequest, apolloParams] = useLazyQuery(GET_FEAUTURED_PRODUCTS_REQUEST, {
    variables: {
      id: Number(id),
    },
  });
  const [featuredProducts, setFeaturedProducts] = useState([]);
  const colorService = new ColorService();
  const { NumberedList } = ProjectsModules;

  useEffect(() => {
    currencyRequest();
  }, []);

  useEffect(() => {
    if (apolloParams.data) {
      setFeaturedProducts(() => apolloParams.data.SuggestedProducts);
    }
  }, [apolloParams]);

  const rowStyle: React.CSSProperties = {
    maxWidth: '1140px',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '40px',
    marginBottom: '40px',
  };

  const settings = {
    dots: true,
    arrows: false,
    fade: true,
    dotsClass: 'slick-dots slick-thumb',
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const openProject = (name: any) => {
    if (name.includes('go.medushkina.com')) {
      window.open(name, '_self');
    } else {
      pathTo(`project/${name}`);
    }
  };

  return (
    <IndexLayout>
      {pageContext.productStrapi ? (
        <>
          {pageContext.productStrapi.blocks &&
            pageContext.productStrapi.blocks.map((block, index) => {
              switch (replaceStrapiComponentName(block?.__component)) {
                case StrapiBlocksName.productSpalsh:
                  return (
                    <LayoutBG background={(block?.theme && block.theme.backgroundColor) || 'none'} key={index}>
                      <Container className={css.productBlockContainer}>
                        <div className={css.productSplashList}>
                          <div className={css.productSlider}>
                            <h2 className={css.productTitle}>{block?.title}</h2>
                            <Slider
                              {...settings}
                              customPaging={i => (
                                <a>
                                  <img src={`${process.env.STRAPI_API_URL}${block?.pictureItems[i]?.picture?.url}`} />
                                </a>
                              )}
                            >
                              {block?.pictureItems &&
                                block.pictureItems.map((item, index) => {
                                  if (item.video) {
                                    return (
                                      <div className={css.videoItemPlayer}>
                                        <ReactPlayer
                                          width="100%"
                                          height="100%"
                                          url={`${process.env.STRAPI_API_URL}${item?.video.url}`}
                                          controls
                                        />
                                      </div>
                                    );
                                  }
                                  if (item.picture) {
                                    return (
                                      <div key={index}>
                                        <img src={`${process.env.STRAPI_API_URL}${item?.picture?.url}`} />
                                      </div>
                                    );
                                  }
                                })}
                            </Slider>
                          </div>
                          <div className={css.productInfo}>
                            <h2 className={css.productTitle}>{block?.title}</h2>
                            <NavStyled className={css.productDesc} bulletIcon={(block?.theme && block.theme.bulletImage.url) || null}>
                              <ReactMarkdown transformImageUri={img => `${process.env.STRAPI_API_URL}${img}`} children={block?.description || ''} remarkPlugins={[remarkGfm]} />
                            </NavStyled>
                            {block?.CTA && block?.CTA?.buttonTitle && (
                              <a className={css.productSplashBtn} href={block.CTA.btnLink || '#'} target="_blank">
                                <Button
                                  title={block.CTA.buttonTitle}
                                  border={false}
                                  bgColor={pageContext.productStrapi.theme?.firstOptionalColor || colors.defaultGradientButton}
                                  shadowColor={colorService.generateShadow(
                                    '3px 3px 15px',
                                    pageContext.productStrapi.theme?.firstOptionalColor || colors.defaultTaplinkButtonShadow
                                  )}
                                  fontColor={colors.white}
                                  fontSize={20}
                                  uppercase
                                  fontWeight={600}
                                />
                              </a>
                            )}
                          </div>
                        </div>
                        <div
                          style={{ color: (pageContext.productStrapi.theme && pageContext.productStrapi.theme.mainColor) || colors.black }}
                          className={css.productExtraInfo}
                        >
                          <ReactMarkdown transformImageUri={img => `${process.env.STRAPI_API_URL}${img}`} children={block?.extraInfo || ''} remarkPlugins={[remarkGfm]} />
                        </div>
                      </Container>
                    </LayoutBG>
                  );
                case StrapiBlocksName.smallItemsList:
                  return (
                    <SmallItemsList
                      data={block}
                      generalTheme={pageContext.productStrapi.theme}
                      type={SmallItemType.column}
                      key={index}
                      backgroundCircle
                    />
                  );
                case StrapiBlocksName.splashWithouCircle:
                  return (
                    <div
                      style={{
                        background: `url(${process.env.STRAPI_API_URL}${block?.theme?.backgroundImage?.url}) center center / cover no-repeat`,
                      }}
                      className={css.splashWithouCircle}
                      key={index}
                    >
                      <Container className={css.productBlockContainer}>
                        <NavStyled bulletIcon={block?.theme?.bulletImage?.url} className={css.splashWithouCircleDesc}>
                          <ReactMarkdown transformImageUri={img => `${process.env.STRAPI_API_URL}${img}`} children={block?.description || ''} remarkPlugins={[remarkGfm]} />
                        </NavStyled>
                      </Container>
                    </div>
                  );
                case StrapiBlocksName.productBulletList:
                  return (
                    <div style={{ background: block?.theme?.backgroundColor || 'none' }} className={css.productBulletList} key={index}>
                      <Container className={css.productBlockContainer}>
                        <StyledTitle color={pageContext.productStrapi.theme?.mainColor} className={css.productBulletListTitle}>
                          <ReactMarkdown transformImageUri={img => `${process.env.STRAPI_API_URL}${img}`} children={block?.title || ''} remarkPlugins={[remarkGfm]} />
                        </StyledTitle>
                        <div className={css.bulletList}>
                          {block?.bulletItem &&
                            block.bulletItem.map((item, index) => (
                              <div
                                style={{ border: `1px solid ${pageContext.productStrapi?.theme?.mainColor || rgba(0, 0, 0, 0)}` }}
                                className={css.bulletItem}
                                key={index}
                              >
                                <img src={`${process.env.STRAPI_API_URL}${item?.picture?.url}`} alt="" />
                                <div className={css.bulletItemDesc}>
                                  <ReactMarkdown transformImageUri={img => `${process.env.STRAPI_API_URL}${img}`} children={item?.description || ''} remarkPlugins={[remarkGfm]} />
                                </div>
                              </div>
                            ))}
                        </div>
                        <div>
                          <div className={css.bulletSelectList}>
                            {block?.bulletSelect &&
                              block.bulletSelect.map((item, index) => (
                                <SelelectItem
                                  key={index}
                                  label={item?.label}
                                  head={item?.head}
                                  mainContent={item?.mainContent}
                                  footer={item?.footer}
                                  additionalTitle={item?.additionalTitle}
                                  color={pageContext.productStrapi?.theme?.mainColor}
                                  arrows={{
                                    labelArrow: item?.labelArrow?.url || null,
                                    bulletArrow: block?.theme?.bulletImage?.url || null,
                                  }}
                                />
                              ))}
                          </div>
                        </div>
                      </Container>
                    </div>
                  );
                case StrapiBlocksName.productVideoBlock:
                  return (
                    <div className={css.productVideoBlock} key={index}>
                      <Container className={css.productBlockContainer}>
                        <StyledTitle color={pageContext.productStrapi.theme?.mainColor} className={css.productVideoBlockTitle}>
                          <ReactMarkdown transformImageUri={img => `${process.env.STRAPI_API_URL}${img}`} children={block?.title || ''} remarkPlugins={[remarkGfm]} />
                        </StyledTitle>
                        <div className={css.videoList}>
                          {block?.videoItems &&
                            block.videoItems.map((item, index) => {
                              return (
                                <div className={css.videoItem} key={index}>
                                  <div className={css.videoItemPlayer}>
                                    <ReactPlayer
                                      width="100%"
                                      height="100%"
                                      url={`${process.env.STRAPI_API_URL}${item?.video.url}`}
                                      controls
                                    />
                                  </div>
                                  <div className={css.videoItemDesc}>
                                    <ReactMarkdown transformImageUri={img => `${process.env.STRAPI_API_URL}${img}`} children={item?.description || ''} remarkPlugins={[remarkGfm]} />
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                        <div className={css.productVideoBlockDesc}>
                          <ReactMarkdown transformImageUri={img => `${process.env.STRAPI_API_URL}${img}`} children={block?.description || ''} remarkPlugins={[remarkGfm]} />
                        </div>
                      </Container>
                    </div>
                  );
                case StrapiBlocksName.productCreator:
                  return (
                    <div style={{ background: block?.theme?.backgroundColor || 'none' }} className={css.productCreator} key={index}>
                      <div className={css.productCreatorHead}>
                        <AdaptiveLayout onMobile>
                          <StyledTitle color={pageContext.productStrapi.theme?.mainColor} className={css.productCreatorTitle}>
                            <ReactMarkdown transformImageUri={img => `${process.env.STRAPI_API_URL}${img}`} children={block?.title || ''} remarkPlugins={[remarkGfm]} />
                          </StyledTitle>
                        </AdaptiveLayout>
                        <div className={css.productCreatorPicture}>
                          <img src={`${process.env.STRAPI_API_URL}${block?.picture?.url}`} alt="" />
                        </div>
                        <div className={css.productCreatorInfo}>
                          <AdaptiveLayout onDesktop onTablet>
                            <StyledTitle color={pageContext.productStrapi.theme?.mainColor} className={css.productCreatorTitle}>
                              <ReactMarkdown transformImageUri={img => `${process.env.STRAPI_API_URL}${img}`} children={block?.title || ''} remarkPlugins={[remarkGfm]} />
                            </StyledTitle>
                          </AdaptiveLayout>
                          <div className={css.productCreatorDesc}>
                            <ReactMarkdown transformImageUri={img => `${process.env.STRAPI_API_URL}${img}`} children={block?.description || ''} remarkPlugins={[remarkGfm]} />
                          </div>
                        </div>
                      </div>
                      <Container className={css.productBlockContainer}>
                        <div className={css.productCreatorSelectList}>
                          {block?.bulletSelect &&
                            block.bulletSelect.map((item, index) => (
                              <SelelectItem
                                key={index}
                                label={item?.label}
                                head={item?.head}
                                mainContent={item?.mainContent}
                                footer={item?.footer}
                                additionalTitle={item?.additionalTitle}
                                color={pageContext.productStrapi?.theme?.mainColor}
                                arrows={{
                                  labelArrow: item?.labelArrow?.url || null,
                                  bulletArrow: block?.theme?.bulletImage?.url || null,
                                }}
                              />
                            ))}
                        </div>
                        {block?.CTA && block.CTA.buttonTitle && !block.cost && (
                          <a className={css.productCreatorBtn} href={block.CTA?.btnLink || '#'} target="_blank">
                            <Button
                              title={block.CTA.buttonTitle}
                              uppercase
                              fontColor={colors.white}
                              fontWeight={FontWeight.bold}
                              fontSize={20}
                              bgColor={pageContext.productStrapi.theme?.mainColor || colors.green}
                              shadowColor="none"
                            />
                          </a>
                        )}
                      </Container>
                      {block?.cost && (
                        <div className={css.selectItemSolo}>
                          <div className={css.selectItem}>
                            <div className={css.label}>
                              <div className={css.labelTitle}>{block.cost.label}</div>
                            </div>
                            <div className={css.selectContent}>
                              {block?.cost.head && (
                                <div className={css.selectHead}>
                                  <ReactMarkdown transformImageUri={img => `${process.env.STRAPI_API_URL}${img}`} children={block.cost.head} remarkPlugins={[remarkGfm]} />
                                </div>
                              )}
                              {block?.cost.mainContent && (
                                <NavStyled className={css.selectmainContent}>
                                  <ReactMarkdown transformImageUri={img => `${process.env.STRAPI_API_URL}${img}`} children={block.cost.mainContent} remarkPlugins={[remarkGfm]} />
                                </NavStyled>
                              )}
                              {block?.cost.additionalTitle && (
                                <div className={css.selectAdditionalTitle}>
                                  <ReactMarkdown transformImageUri={img => `${process.env.STRAPI_API_URL}${img}`} children={block.cost.additionalTitle} remarkPlugins={[remarkGfm]} />
                                </div>
                              )}
                              {block?.cost.footer && (
                                <NavStyled className={css.selectFooter}>
                                  <StyledTitle color={pageContext.productStrapi.theme?.mainColor || colors.black}>
                                    <ReactMarkdown transformImageUri={img => `${process.env.STRAPI_API_URL}${img}`} children={block.cost.footer} remarkPlugins={[remarkGfm]} />
                                  </StyledTitle>
                                </NavStyled>
                              )}
                              {block?.CTA && block.CTA.buttonTitle && (
                                <a className={css.productCreatorBtn} href={block.CTA?.btnLink || '#'} target="_blank">
                                  <Button
                                    title={block.CTA.buttonTitle}
                                    uppercase
                                    fontColor={colors.white}
                                    fontWeight={FontWeight.bold}
                                    fontSize={20}
                                    bgColor={pageContext.productStrapi.theme?.mainColor || colors.green}
                                    shadowColor="none"
                                  />
                                </a>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  );
                case StrapiBlocksName.reviewSlider:
                  return <SliderBlock data={block} type={SliderBlocksType.reviews} key={index} />;
                case StrapiBlocksName.numberedList:
                  return <NumberedList data={block} generalTheme={pageContext.productStrapi.theme} key={index} />;
                case StrapiBlocksName.productConnect:
                  return (
                    <div className={css.productConnect} key={index}>
                      <Container className={css.productBlockContainer}>
                        <h2 className={css.productConnectTitle}>{block?.title}</h2>
                        <div className={css.productConnectDesc}>
                          <ReactMarkdown transformImageUri={img => `${process.env.STRAPI_API_URL}${img}`} children={block?.description || ''} remarkPlugins={[remarkGfm]} />
                        </div>
                        {block?.CTA && block.CTA.buttonTitle && (
                          <>
                            <AdaptiveLayout onDesktop onTablet>
                              <a className={css.productConnectBtn} href={block.CTA?.btnLink || '#'} target="_blank">
                                <Button
                                  border
                                  from={pageContext.productStrapi.theme?.mainColor || colors.green}
                                  to={pageContext.productStrapi.theme?.mainColor || colors.green}
                                  title={block.CTA.buttonTitle}
                                  uppercase
                                  fontColor={colors.white}
                                  fontWeight={FontWeight.normal}
                                  fontSize={18}
                                  bgColor={pageContext.productStrapi.theme?.mainColor || colors.green}
                                  shadowColor="none"
                                />
                              </a>
                            </AdaptiveLayout>
                            <AdaptiveLayout onMobile>
                              <a className={css.productConnectBtn} href={block.CTA?.btnLink || '#'} target="_blank">
                                <Button
                                  border
                                  from={pageContext.productStrapi.theme?.mainColor || colors.green}
                                  to={pageContext.productStrapi.theme?.mainColor || colors.green}
                                  title={block.CTA.buttonTitle}
                                  uppercase
                                  fontColor={colors.white}
                                  fontWeight={FontWeight.normal}
                                  fontSize={14}
                                  bgColor={pageContext.productStrapi.theme?.mainColor || colors.green}
                                  shadowColor="none"
                                />
                              </a>
                            </AdaptiveLayout>
                          </>
                        )}
                      </Container>
                    </div>
                  );
                default:
                  return null;
              }
            })}
          <div className={css.onlineProgram}>
            <Container className={css.onlineProgramContainer}>
              <h2 className={css.onlineProgramTitle}>Попробуйте готовые онлайн-программы тренировок и питания </h2>
              <div className={css.olinePropgramList}>
                {projects &&
                  projects
                    ?.filter(project => project.pageInfo && project.pageInfo.category === ProjectCategories.slimming)
                    .map(
                      (project, index) =>
                        (
                          <div className={css.olinePropgramItem} key={index}>
                            <img src={`${process.env.STRAPI_API_URL}${project.pageInfo?.picture?.url}`} alt="" />
                            <div className={css.olinePropgramInfo}>
                              <h4
                                style={{ color: pageContext.productStrapi.theme?.mainColor || colors.black }}
                                className={css.olinePropgramItemTitle}
                              >
                                {project.pageInfo?.title}
                              </h4>
                              <div className={css.olinePropgramItemDuration}>
                                <h5>
                                  Дата старта: <span>{project.pageInfo?.date}</span>
                                </h5>
                                <h5>
                                  Длительность: <span>{project.pageInfo?.duration}</span>
                                </h5>
                                <div className={css.olinePropgramItemBtn} onClick={() => openProject(project.Name)}>
                                  Подробнее об интенсиве
                                </div>
                              </div>
                            </div>
                          </div>
                        ) || null
                    )}
              </div>
              <Link className={css.moreProgramBtn} to={pathTo('/')}>
                <>
                  <AdaptiveLayout onDesktop onTablet>
                    <Button
                      title="БОЛЬШЕ КУРСОВ"
                      uppercase
                      fontColor={colors.white}
                      fontWeight={FontWeight.normal}
                      fontSize={18}
                      bgColor={pageContext.productStrapi.theme?.mainColor || colors.green}
                      shadowColor="none"
                    />
                  </AdaptiveLayout>
                  <AdaptiveLayout onMobile>
                    <Button
                      title="БОЛЬШЕ КУРСОВ"
                      uppercase
                      fontColor={colors.white}
                      fontWeight={FontWeight.normal}
                      fontSize={14}
                      bgColor={pageContext.productStrapi.theme?.mainColor || colors.green}
                      shadowColor="none"
                    />
                  </AdaptiveLayout>
                </>
              </Link>
            </Container>
          </div>
        </>
      ) : (
        <Page>
          {product && (
            <>
              <Row style={rowStyle}>
                <Col lg={16} md={24}>
                  {product && !!product.photos ? <Gallery images={product.photos} /> : <img src={productImageStub} alt={product.slug} />}
                </Col>
                <Col lg={8} md={24}>
                  <ProductItem product={product} type={ProductCartType.description} />
                </Col>
              </Row>
              {product && product.description && product.description[i18n.language as LanguageType] && (
                <Row style={rowStyle}>
                  <Tabs type="card" tabPosition={isXS || isMD ? 'top' : 'left'} className={css.tabs}>
                    <TabPane tab={t('shell:product.tabs.description')} key={t('shell:product.tabs.description')}>
                      {parse(product.description[i18n.language as LanguageType] as string)}
                    </TabPane>
                    {product.inDetail.material?.measurement && (
                      <TabPane tab={t('shell:product.tabs.measurement')} key={t('shell:product.tabs.measurement')}>
                        <Descriptions column={2} size="small" bordered>
                          {product.inDetail.material?.measurement.height && (
                            <Descriptions.Item label={t('shell:product.measurement.height')}>
                              {product.inDetail.material?.measurement.height}{' '}
                              {t(`shell:product.measurement.units.${product.inDetail.material?.measurement.unit}`)}
                            </Descriptions.Item>
                          )}
                          {product.inDetail.material?.measurement.width && (
                            <Descriptions.Item label={t('shell:product.measurement.width')}>
                              {product.inDetail.material?.measurement.width}{' '}
                              {t(`shell:product.measurement.units.${product.inDetail.material?.measurement.unit}`)}
                            </Descriptions.Item>
                          )}
                          {product.inDetail.material?.measurement.depth && (
                            <Descriptions.Item label={t('shell:product.measurement.depth')}>
                              {product.inDetail.material?.measurement.depth}{' '}
                              {t(`shell:product.measurement.units.${product.inDetail.material?.measurement.unit}`)}
                            </Descriptions.Item>
                          )}
                          {product.inDetail.material?.measurement.weight && (
                            <Descriptions.Item label={t('shell:product.measurement.weight')}>
                              {product.inDetail.material?.measurement.weight}
                            </Descriptions.Item>
                          )}
                        </Descriptions>
                      </TabPane>
                    )}
                  </Tabs>
                </Row>
              )}
            </>
          )}
          {featuredProducts.length !== 0 && (
            <Container className={css.container}>
              <h2 className={css.featuredTitle}>{t('status:description.product.featured.title')}</h2>
              <Divider />
              <div className={css.featuredList}>
                {!apolloParams.loading &&
                  categories &&
                  featuredProducts.map((card: PayformProductResponseDto) => {
                    return <CardItem key={card.id} type={CardItemType.featured} id={card.id} details={card} />;
                  })}
              </div>
              {apolloParams.loading && (
                <div className={css.spinWrapper}>
                  <Spin />
                </div>
              )}
            </Container>
          )}
        </Page>
      )}
    </IndexLayout>
  );
};

export default PageTemplate;
